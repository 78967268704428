import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'

import Layout from "layout/layout"
// import Image from "components/image"
import SEO from "components/seo"
import theme from 'utils/theme';

const PagePadding = {
  padding: `0 0 40px 0`,
}

const AboutDeconDesign = styled.div`
  width: 100%;
  max-width: 680px;
  margin: 0 auto;
`;

const EmphasisSpanStyle = {
  fontWeight: theme.fontWeight.semibold,
}

const AboutPage = () => (
  <Layout>
    <div className="container" style={PagePadding}>
      <div className="row">
        <div className="col-12">
          <SEO title={`About`} />
          <AboutDeconDesign>
            <h1>About</h1>

            <h3>Hi, I’m Patrick! 👋</h3>
            <p>I created Deconstructing Design because it was the one resource I wish I had while initially teaching myself about design. What I wanted was a single place that outlined quality resources, insightful reads, and helpful people I could learn from in the design community.</p>
            <p>I didn’t want to spend my time searching. I just wanted to learn.</p>
            <p>So, that’s what I’ve done.</p>
            <p>Everything on Deconstructing Design is either a resource I’ve learned from in the past, or something/someone I’ve had recommended to me that I plan on learning from in the near future.</p>
            <p><span style={EmphasisSpanStyle}>Those of you who are brand new to design and just starting your journey</span>, welcome! Not only do I hope you find this useful, but I’m continually trying to improve my own design chops and sharing as much of that as I can along the way. Let me know how I can help in any way!</p>
            <p><span style={EmphasisSpanStyle}>And to those who are seasoned designers</span> here just to check it out, don’t hesitate to reach out if there are resources you found helpful along your journey, or that you frequently recommend to others. I’ll happily add them to the collection.</p>
            
            <br />
            <h3>My story</h3>
            <p>A couple years ago, in mid-2017, I decided to finally make the transition from software engineering to product design. There were many factors that went into the decision that I’m sure I’ll elaborate on in a full-length post at some point, but in short, the role of a product designer of digital products sounded like a perfect fit – my <a href={`https://www.sloww.co/ikigai/`}>Ikigai</a>.</p>
            <p>Since I was a little kid, there are two things that I’ve found endlessly fascinating: how things work, and why people do what they do.</p>
            <p><span style={EmphasisSpanStyle}>How things work.</span> The draw to building and creating things is something I’ve had since a little kid. From the earliest days with legos and Hot Wheels track sets, then to model cars and hobby store rockets, and then on to anything with an engine. I’ve been tinkering my entire life. The process of deconstructing, learning, and understanding how things work is something I could do for hours without skipping a beat.</p>
            <p><span style={EmphasisSpanStyle}>Why people do what they do.</span> This was also driven by curiosity, but I didn’t know the field of study had a name until later into high school and college. It’s called Psychology (whata ya know!). Questions like “Why do people buy this product over that product?”, “Why do people find this thing easier to use than that?”, or “What about this form of communication is more receptive than that type?” I could nerd out about that kind of stuff all day long.</p>
            <p>The former interest is what led me to software engineering.</p>
            <p>For years and years I had thought software engineers were "nerds" that were good at math and into gaming. While some may fit that stereotype, which is totally cool, it didn’t seem like it was right for me. I wrote it off entirely.</p>
            <p>Then everything changed when the iPhone came out.</p>
            <p>I had all kinds of ideas for apps that I thought would be cool, so I took a college summer school class in 2011 to learn how to build apps and loved it. In the last week of that class I switched my major to Computer Science from Business, one of the best decisions I ever made, and the rest was history. I started coding all over the stack from back-end to mobile apps to web apps. Having the ability to come up with an idea, create it, and bring it to life was my childhood dream come true.</p>
            <p>After working on Adobe’s Experience Design team with some incredible designers, then to a few early stage startups with great product designers, I found myself wanting to create and communicate my ideas even faster than I could code them.</p>
            <p>I loved how fast I could come up with an idea for the products I was coding, walk over to a designer's desk, ideate on an experience, and they could make it “real” in minutes using XD, Sketch, or Figma, along with various prototyping tools.</p>
            <p>That seemed like a superpower.</p>
            <p>After working as a front-end engineer on many web and mobile apps, I had developed a strong foundation in what well designed products should look and feel like, I had just never gone through that process from the designer’s perspective.</p>
            <p>I started learning everything I could about the foundations of typography, hierarchy, contrast, space, user research, motion, and anything else I could get my hands on.</p>
            <p>Now, after working as a professional product designer for a few years, I’ve had hundreds of thousands if not millions of people use the consumer products I designed while at Affirm, took on a few freelance projects for other early-stage startups, and even created some of my own apps.</p>
            <p>It’s a craft that I can’t get enough of, and still feel like I have so much left to learn about the craft.</p>
            
            <br />
            <h3>So, what’s next?</h3>
            <p>Because I was all self-taught from the start, I have so many things I still want to learn and explore in the field of design. I’d love to further my understanding of typography. I’m interested in becoming a better artist and dabbling in illustration. I’d like to dive deeper into branding and what the process is like to create that from start to finish. Package design is something that seems just as foreign to me now as software engineering felt to me nearly a decade ago.</p>
            <p>It all sounds fascinating.</p>
            <p>Deconstructing Design is going to be my place to explore these topics and share my learnings with whoever else is interested.</p>
            <p>I came across this post about <a href={`https://www.swyx.io/writing/learn-in-public/`}>learning in public</a>, and I haven’t been able to stop thinking about it since. I’ll be learning in public through Deconstructing Design. At worst, I’ll have all of my work publicly accessible to anyone interested in following along, and at best, I’ll start creating a collection of resources and community of designers who are all going through their own learning journeys, too.</p>
            <p>I’m looking forward to sharing these explorations on the <Link to={`blog`}>blog</Link>, and equally excited to hear from anyone else who’s also going through this process – beginner, student, curious to learn more, looking to become a professional, or even seasoned professional eager to share.</p>
            <p>Feel free to reach out anytime. I'm @deecondesign on <a href={`https://twitter.com/deecondesign`}>Twitter</a> and <a href={`https://instagram.com/deecondesign`}>Instagram</a>, or email me direct at <a href="mailto:patrick@deconstructingdesign.com">patrick@deconstructingdesign.com</a>.</p>
          </AboutDeconDesign>
        </div>
      </div>
    </div>
  </Layout>
)

export default AboutPage
